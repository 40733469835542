var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "app-main",
    attrs: {
      "id": "main"
    }
  }, [_c('router-view')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };