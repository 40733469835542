export default {
  xboot: 'XBoot is an excellent one-station front-end and back-end separation fast development platform',
  usernameLogin: 'Username Sign in',
  mobileLogin: 'Phone',
  autoLogin: 'Remember me',
  forgetPass: 'Forget password',
  otherLogin: 'Sign in with',
  registerAccount: 'Register',
  register: "Register",
  registering: "Registering...",
  registerNow: "No account yet？Register now",
  loginNow: "Sign in with an account",
  login: 'Sign in',
  logining: 'Signing in...',
  home: 'Home',
  xbootAdmin: 'XBoot Manage',
  appAdmin: 'App Manage (toC)',
  xbootMaterial: 'XBoot Material',
  devDocs: 'Dev Docs',
  permTestPage: 'Permission Test Page',
  ssoTestPage: 'SSO Test Page',
  more: 'More',
  searchMenu: 'Search Menu...',
  fullscreen: 'Fullscreen',
  exitFullscreen: 'Exit Fullscreen',
  Notifications: 'Notifications',
  showMore: 'View More',
  markAll: 'Mark all as read',
  refresh: 'Refresh',
  noNewMes: 'There are no new unread messages',
  userCenter: 'Account Center',
  changePass: 'Change Password',
  logout: 'Sign out',
  closeAll: 'Close All',
  closeOthers: 'Close Others',
  docs: 'Docs',
  fullVersion: 'XBoot Plus',
  rights: 'All Rights Reserved',
  sso: "XBoot Authentication Platform",
  authorize: "Authorize",
  authorizeAndSignin: "Authorize and Sign in",
  authorizing: "Authorizing...",
  wants: "wants to access your account",
  wants2: "",
  authAquire: "Public data only",
  aquireInfo: "Limited access to your public data",
  afterAuth: "Authorizing will redirect to",
  relateTitle: "Bind XBoot's Account",
  relate: "Bind",
  relating: "Binding...",
  themeTitle: "Theme Style Setting",
  wholeSetting: "Whole Style Preset",
  lightblue: "Light Blue Style",
  topNav: "Menu Top Fixed Style",
  darkblue: "Dark Blue Style",
  darkMode: "Dark Mode",
  dark: "Dark Style",
  middle: "Neutral Style",
  white: "Light White Style",
  sideMenu: "Side Menu Style",
  navMenu: "Navigation Style",
  navMode: "Navigation Mode",
  rightTop: "Right Top",
  topFix: "Top Fixed",
  navType: "Nav Show Type",
  horizontal: "Horizontal",
  sliceNum: "Default show number",
  singleDrop: "Single Dropdown",
  iconBreadcrumb: "Icon+Breadcrumb",
  showIcon: "Show Icon",
  dropMenu: "DropMenu Type",
  appStyle: "App Icon Style",
  listStyle: "List Mode",
  contentArea: "Content Area",
  enableTags: "Enable Multi-Tags",
  showLogo: "Show Logo",
  showFold: "Show Fold Menu Icon",
  showSearchMenu: "Show Search Menu",
  showFooter: "Show Footer",
  sideMenuAccordion: "Side Menu Accordion Mode",
  sideMenuWidth: "Side Menu Width",
  waterMark: "Watermark Setting",
  openWaterMark: "Open Watermark",
  opacity: "Opacity",
  fontSize: "Font Size",
  otherSetting: "Other Setting",
  weakMode: "Weak Mode",
  themeInfo: "This is mainly for previewing the layout. It is recommended that only some functions are opened or closed for users in the production environment"
};