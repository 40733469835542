import apps from "./apps";
export default {
  name: "nav-app",
  components: {
    apps
  },
  props: {
    navList: Array,
    currNav: String,
    showIcon: {
      type: Boolean,
      default: true
    },
    sliceNum: {
      type: Number,
      default: 3
    },
    theme: {
      type: String,
      default: "light"
    }
  },
  data() {
    return {};
  },
  methods: {
    init() {},
    itemTitle(item) {
      if (item.localize && item.i18n) {
        return this.$t(item.i18n);
      } else {
        return item.title;
      }
    },
    selectNav(v) {
      this.$emit("on-click", v);
    }
  },
  mounted() {
    this.init();
  },
  watch: {}
};