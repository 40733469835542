var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('Icon', {
    staticClass: "spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18",
      "color": "#2d8cf0"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };