var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('i', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_vm.checkIcon(_vm.type) == 'icon' ? _c('Icon', {
    attrs: {
      "type": _vm.type,
      "size": _vm.size,
      "color": _vm.color
    }
  }) : _vm._e(), _vm.checkIcon(_vm.type) == 'iconfont' ? _c('Icon', {
    attrs: {
      "custom": _vm.type,
      "size": _vm.size,
      "color": _vm.color
    }
  }) : _vm._e(), _vm.checkIcon(_vm.type) == 'link' ? _c('img', {
    staticStyle: {
      "vertical-align": "-0.125em"
    },
    attrs: {
      "src": _vm.type,
      "width": _vm.size + 'px',
      "height": _vm.size + 'px'
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };