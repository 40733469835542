export default {
  name: "setPassword",
  props: {
    value: String,
    size: String,
    placeholder: {
      type: String,
      default: "请输入密码，长度为6-20个字符"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      currentValue: this.value,
      tipStyle: "password-tip-none",
      strengthValue: 0,
      progressStatus: "normal",
      strength: "无",
      grade: 0
    };
  },
  methods: {
    checkStrengthValue(v) {
      // 评级制判断密码强度 最高5
      let grade = 0;
      if (/\d/.test(v)) {
        grade++; //数字
      }

      if (/[a-z]/.test(v)) {
        grade++; //小写
      }

      if (/[A-Z]/.test(v)) {
        grade++; //大写
      }

      if (/\W/.test(v)) {
        grade++; //特殊字符
      }

      if (v.length >= 10) {
        grade++;
      }
      this.grade = grade;
      return grade;
    },
    strengthChange() {
      if (!this.currentValue) {
        this.tipStyle = "password-tip-none";
        this.strength = "无";
        this.strengthValue = 0;
        return;
      }
      let grade = this.checkStrengthValue(this.currentValue);
      if (grade <= 1) {
        this.progressStatus = "wrong";
        this.tipStyle = "password-tip-weak";
        this.strength = "弱";
        this.strengthValue = 33;
      } else if (grade >= 2 && grade <= 4) {
        this.progressStatus = "normal";
        this.tipStyle = "password-tip-middle";
        this.strength = "中";
        this.strengthValue = 66;
      } else {
        this.progressStatus = "success";
        this.tipStyle = "password-tip-strong";
        this.strength = "强";
        this.strengthValue = 100;
      }
    },
    handleChange(v) {
      this.strengthChange();
      this.$emit("input", this.currentValue);
      this.$emit("on-change", this.currentValue, this.grade, this.strength);
    },
    setCurrentValue(value) {
      if (value === this.currentValue) {
        return;
      }
      this.currentValue = value;
      this.strengthChange();
      this.$emit("on-change", this.currentValue, this.grade, this.strength);
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    }
  }
};