export default {
  name: "breadcrumbNav",
  props: {
    currentPath: Array,
    theme: {
      type: String,
      default: "light"
    }
  },
  methods: {
    itemTitle(item) {
      if (item.localize && item.i18n) {
        return this.$t(item.i18n);
      } else {
        return item.title;
      }
    }
  }
};