export default {
  name: "apps",
  props: {
    navList: Array,
    currNav: String
  },
  data() {
    return {};
  },
  methods: {
    itemTitle(item) {
      if (item.localize && item.i18n) {
        return this.$t(item.i18n);
      } else {
        return item.title;
      }
    },
    selectNav(v) {
      this.$emit("on-click", v);
    }
  },
  watch: {}
};