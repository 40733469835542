var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('Menu', {
    attrs: {
      "theme": _vm.theme,
      "mode": "horizontal",
      "active-name": _vm.currNav
    },
    on: {
      "on-select": _vm.selectNav
    }
  }, [_vm._l(_vm.navList.slice(0, _vm.sliceNum), function (item, i) {
    return _c('MenuItem', {
      key: i,
      attrs: {
        "name": item.name
      }
    }, [_c('Badge', {
      attrs: {
        "dot": item.component == 'hot',
        "offset": [20, 0]
      }
    }, [_vm.showIcon ? _c('XIcon', {
      attrs: {
        "type": item.icon
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(_vm.itemTitle(item)) + " ")], 1)], 1);
  }), _vm.navList.length > _vm.sliceNum ? _c('Submenu', {
    attrs: {
      "name": "sub"
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.$t("more")))]), _vm._l(_vm.navList.slice(_vm.sliceNum, _vm.navList.length), function (item, i) {
    return _c('MenuItem', {
      key: i,
      attrs: {
        "name": item.name
      }
    }, [_c('Badge', {
      attrs: {
        "dot": item.component == 'hot'
      }
    }, [_vm.showIcon ? _c('XIcon', {
      staticStyle: {
        "margin-right": "6px"
      },
      attrs: {
        "type": item.icon
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(_vm.itemTitle(item)) + " ")], 1)], 1);
  })], 2) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };