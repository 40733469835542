var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Cascader', {
    attrs: {
      "data": _vm.data,
      "load-data": _vm.loadData,
      "transfer": "",
      "disabled": _vm.disabled,
      "size": _vm.size,
      "placeholder": _vm.placeholder,
      "render-format": _vm.renderFormat
    },
    on: {
      "on-change": _vm.handleChange
    },
    model: {
      value: _vm.select,
      callback: function ($$v) {
        _vm.select = $$v;
      },
      expression: "select"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };