var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Menu', {
    ref: "sideMenu",
    attrs: {
      "accordion": _vm.accordion,
      "active-name": _vm.$route.name,
      "open-names": _vm.openNames,
      "theme": _vm.theme,
      "width": "auto"
    },
    on: {
      "on-select": _vm.changeMenu
    }
  }, [_vm._l(_vm.menuList, function (item) {
    return [item.level == '1' && !item.showAlways ? [item.children.length <= 1 ? _c('MenuItem', {
      key: 'menuitem' + item.name,
      attrs: {
        "name": item.children[0].name
      }
    }, [_c('XIcon', {
      key: 'menuicon' + item.name,
      staticStyle: {
        "margin-right": "16px"
      },
      attrs: {
        "type": item.children[0].icon || item.icon,
        "size": _vm.iconSize
      }
    }), _c('span', {
      key: 'title' + item.name,
      staticClass: "content-text"
    }, [_vm._v(_vm._s(_vm.itemTitle(item.children[0])))])], 1) : _vm._e(), item.children.length > 1 ? _c('Submenu', {
      key: item.name,
      attrs: {
        "name": item.name
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('XIcon', {
      attrs: {
        "type": item.icon,
        "size": _vm.iconSize
      }
    }), _c('span', {
      staticClass: "content-text"
    }, [_vm._v(_vm._s(_vm.itemTitle(item)))])], 1), _vm._l(item.children, function (child) {
      return [_c('MenuItem', {
        key: 'menuitem' + child.name,
        attrs: {
          "name": child.name
        }
      }, [_c('XIcon', {
        key: 'icon' + child.name,
        attrs: {
          "type": child.icon,
          "size": _vm.iconSize
        }
      }), _c('span', {
        key: 'title' + child.name,
        staticClass: "content-text"
      }, [_vm._v(_vm._s(_vm.itemTitle(child)))])], 1)];
    })], 2) : _vm._e()] : [_c('Submenu', {
      key: item.name,
      attrs: {
        "name": item.name
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('XIcon', {
      attrs: {
        "type": item.icon,
        "size": _vm.iconSize
      }
    }), _c('span', {
      staticClass: "content-text"
    }, [_vm._v(_vm._s(_vm.itemTitle(item)))])], 1), _vm._l(item.children, function (child) {
      return [_c('MenuItem', {
        key: 'menuitem' + child.name,
        attrs: {
          "name": child.name
        }
      }, [_c('XIcon', {
        key: 'icon' + child.name,
        attrs: {
          "type": child.icon,
          "size": _vm.iconSize
        }
      }), _c('span', {
        key: 'title' + child.name,
        staticClass: "content-text"
      }, [_vm._v(_vm._s(_vm.itemTitle(child)))])], 1)];
    })], 2)]];
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };