var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "message-content",
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('Dropdown', {
    attrs: {
      "trigger": "click",
      "placement": "bottom-end"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('div', {
    staticClass: "header-right-icon header-action"
  }, [_c('Badge', {
    attrs: {
      "count": _vm.messageCount
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin-top": "3px"
    },
    attrs: {
      "type": "md-notifications",
      "size": 20
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "message-tip",
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("Notifications")))]), _vm.messageList.length > 0 ? _c('a', {
    staticClass: "mark",
    on: {
      "click": _vm.markAll
    }
  }, [_vm._v(_vm._s(_vm.$t("markAll")))]) : _c('a', {
    staticClass: "mark",
    on: {
      "click": _vm.getData
    }
  }, [_vm._v(_vm._s(_vm.$t("refresh")))])]), _c('div', {
    staticClass: "mes-list"
  }, [_vm.messageList.length == 0 ? _c('div', {
    staticClass: "empty-message"
  }, [_c('Icon', {
    attrs: {
      "type": "ios-mail-open-outline",
      "size": "70",
      "color": "#e4e4e4"
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("noNewMes")))])], 1) : _c('div', _vm._l(_vm.messageList, function (item, index) {
    return _c('div', {
      key: index,
      class: {
        'mes-item': item.status == 0,
        'mes-item opacity': item.status == 1
      },
      on: {
        "click": function ($event) {
          return _vm.messageDetail(item);
        }
      }
    }, [_c('div', {
      staticClass: "icon"
    }, [item.type == '通知' ? _c('Avatar', {
      staticClass: "icon",
      staticStyle: {
        "background-color": "#ff9900"
      },
      attrs: {
        "icon": "md-send"
      }
    }) : item.type == '提醒' ? _c('Avatar', {
      staticClass: "icon",
      staticStyle: {
        "background-color": "#ed4014"
      },
      attrs: {
        "icon": "md-alarm"
      }
    }) : item.type == '私信' ? _c('Avatar', {
      staticClass: "icon",
      staticStyle: {
        "background-color": "#87d068"
      },
      attrs: {
        "icon": "md-person"
      }
    }) : item.type == '工作流' ? _c('Avatar', {
      staticClass: "icon",
      staticStyle: {
        "background-color": "#2db7f5"
      },
      attrs: {
        "icon": "md-briefcase"
      }
    }) : item.type == '系统公告' ? _c('Avatar', {
      staticClass: "icon",
      staticStyle: {
        "background-color": "#2d8cf0"
      },
      attrs: {
        "icon": "md-mail"
      }
    }) : _c('Avatar', {
      staticClass: "icon",
      attrs: {
        "icon": "ios-mail"
      }
    })], 1), _c('div', {
      staticClass: "detail"
    }, [_c('div', {
      staticClass: "mes-title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "mes-time"
    }, [_c('Icon', {
      staticStyle: {
        "margin-right": "3px"
      },
      attrs: {
        "type": "md-time"
      }
    }), _c('Time', {
      attrs: {
        "time": item.createTime
      }
    })], 1)])]);
  }), 0), _vm.loading ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }) : _vm._e()], 1), _c('a', {
    staticClass: "show-more",
    on: {
      "click": _vm.showMessage
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin": "0 5px 2px 0"
    },
    attrs: {
      "type": "ios-arrow-dropright-circle",
      "size": "16"
    }
  }), _vm._v(_vm._s(_vm.$t("showMore")) + " ")], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };