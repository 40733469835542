var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('Dropdown', {
    attrs: {
      "transfer": "",
      "trigger": "hover"
    },
    on: {
      "on-click": _vm.handleClickUserDropdown
    }
  }, [_c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('div', {
    staticClass: "header-right-icon header-user header-action"
  }, [_vm.avatar ? _c('Avatar', {
    attrs: {
      "src": _vm.avatar,
      "size": "small"
    }
  }) : _c('Avatar', {
    attrs: {
      "icon": "md-person",
      "size": "small"
    }
  }), _c('span', {
    staticClass: "header-username"
  }, [_vm._v(_vm._s(_vm.nickname))])], 1)]), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', {
    attrs: {
      "name": "ownSpace"
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin": "0 10px 0 0"
    },
    attrs: {
      "type": "md-person"
    }
  }), _vm._v(_vm._s(_vm.$t("userCenter")))], 1), _c('DropdownItem', {
    attrs: {
      "name": "changePass"
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin": "0 10px 0 0"
    },
    attrs: {
      "type": "md-unlock"
    }
  }), _vm._v(_vm._s(_vm.$t("changePass")))], 1), _c('DropdownItem', {
    attrs: {
      "name": "loginout",
      "divided": ""
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin": "0 10px 0 0"
    },
    attrs: {
      "type": "md-log-out"
    }
  }), _vm._v(_vm._s(_vm.$t("logout")))], 1)], 1)], 1), _c('changePass', {
    model: {
      value: _vm.showChangePass,
      callback: function ($$v) {
        _vm.showChangePass = $$v;
      },
      expression: "showChangePass"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };