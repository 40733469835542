var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.type == 'input' ? _c('div', {
    class: `search-menu search-menu-theme-${_vm.theme}`
  }, [_c('Select', {
    ref: "select",
    attrs: {
      "prefix": "ios-search",
      "transfer": "",
      "clearable": "",
      "filterable": "",
      "placeholder": _vm.$t('searchMenu')
    },
    on: {
      "on-change": _vm.changeMenu
    },
    model: {
      value: _vm.key,
      callback: function ($$v) {
        _vm.key = $$v;
      },
      expression: "key"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('Option', {
      key: index,
      attrs: {
        "value": item.name
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1), _c('Icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.key,
      expression: "key"
    }],
    staticClass: "close-icon",
    attrs: {
      "type": "ios-close-circle"
    },
    on: {
      "click": _vm.clear
    }
  })], 1) : _c('div', {
    staticClass: "search-dropdown"
  }, [_c('Dropdown', {
    attrs: {
      "trigger": "click",
      "placement": "bottom-start"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('div', {
    staticClass: "header-right-icon header-action"
  }, [_c('Icon', {
    attrs: {
      "type": "ios-search",
      "size": 20
    }
  })], 1)]), _c('div', {
    staticClass: "search-select",
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('Select', {
    ref: "select",
    attrs: {
      "prefix": "ios-search",
      "transfer": "",
      "clearable": "",
      "filterable": "",
      "placeholder": _vm.$t('searchMenu')
    },
    on: {
      "on-change": _vm.changeMenu
    },
    model: {
      value: _vm.key,
      callback: function ($$v) {
        _vm.key = $$v;
      },
      expression: "key"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('Option', {
      key: index,
      attrs: {
        "value": item.name
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };