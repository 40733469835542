import { getOtherSet } from "@/api/index";
import util from "@/libs/util.js";
import changePass from "@/views/change-pass/change-pass";
import Cookies from 'js-cookie';
export default {
  name: "user",
  components: {
    changePass
  },
  props: {},
  computed: {
    nickname() {
      return this.$store.state.user.nickname;
    },
    avatar() {
      return this.$store.state.user.avatar;
    }
  },
  data() {
    return {
      showChangePass: false
    };
  },
  methods: {
    init() {},
    handleClickUserDropdown(name) {
      if (name == "ownSpace") {
        util.openNewPage(this, "ownspace_index");
        this.$router.push({
          name: "ownspace_index"
        });
      } else if (name == "changePass") {
        this.showChangePass = true;
      } else if (name == "loginout") {
        // 退出登录
        this.$store.commit("setLoading", true);
        getOtherSet().then(res => {
          this.$store.commit("setLoading", false);
          if (res.result) {
            let domain = res.result.ssoDomain;
            Cookies.set("accessToken", "", {
              domain: domain,
              expires: 7
            });
          }
          this.$store.commit("logout", this);
          this.setStore("accessToken", "");
          // 强制刷新页面 重新加载router
          location.reload();
        });
      }
    }
  },
  mounted() {
    this.init();
  }
};