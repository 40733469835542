var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nav-app"
  }, _vm._l(_vm.navList, function (item, i) {
    return _c('div', {
      key: i,
      class: {
        'nav-item': _vm.currNav != item.name,
        'nav-item active': _vm.currNav == item.name
      },
      attrs: {
        "name": item.name
      },
      on: {
        "click": function ($event) {
          return _vm.selectNav(item.name);
        }
      }
    }, [_c('Badge', {
      attrs: {
        "dot": item.component == 'hot'
      }
    }, [_c('XIcon', {
      attrs: {
        "type": item.icon,
        "size": "24"
      }
    })], 1), _c('div', {
      staticClass: "nav-title-wrap"
    }, [_c('div', {
      staticClass: "nav-title"
    }, [_vm._v(_vm._s(_vm.itemTitle(item)))])])], 1);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };