export default {
  data() {
    return {};
  },
  computed: {
    mainTheme() {
      return this.$store.state.theme.theme.mainTheme;
    }
  },
  mounted() {
    // 主题预设
    this.changeMode();
  },
  beforeDestroy() {},
  methods: {
    changeMode() {
      let v = this.mainTheme;
      if (v == "darkMode") {
        document.getElementsByTagName("body")[0].className = "darkMode";
      } else if (v == "weakMode") {
        document.getElementsByTagName("body")[0].className = "weakMode";
      } else {
        document.getElementsByTagName("body")[0].className = "";
      }
    }
  },
  watch: {
    mainTheme() {
      this.changeMode();
    }
  }
};