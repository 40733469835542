export default {
  name: "language",
  props: {},
  data() {
    return {
      currLang: "zh-CN"
    };
  },
  methods: {
    handleLanDropdown(name) {
      this.currLang = name;
      this.$i18n.locale = name;
      this.$store.commit("switchLang", name);
    }
  },
  mounted() {
    if (localStorage.lang) {
      this.currLang = localStorage.lang;
    }
  }
};