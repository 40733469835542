export default {
  name: "sidebarMenuShrink",
  props: {
    menuList: {
      type: Array
    },
    theme: {
      type: String,
      default: "darkblue"
    },
    openNames: {
      type: Array
    }
  },
  methods: {
    changeMenu(active) {
      this.$emit("on-change", active);
    },
    itemTitle(item) {
      if (item.localize && item.i18n) {
        return this.$t(item.i18n);
      } else {
        return item.title;
      }
    }
  }
};