var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "set-password"
  }, [_c('Poptip', {
    attrs: {
      "transfer": "",
      "trigger": "focus",
      "placement": "right",
      "width": "250"
    }
  }, [_c('Input', {
    attrs: {
      "type": "password",
      "password": "",
      "maxlength": _vm.maxlength,
      "size": _vm.size,
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly
    },
    on: {
      "on-change": _vm.handleChange
    },
    model: {
      value: _vm.currentValue,
      callback: function ($$v) {
        _vm.currentValue = $$v;
      },
      expression: "currentValue"
    }
  }), _c('div', {
    class: _vm.tipStyle,
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('div', {
    staticClass: "words"
  }, [_vm._v("强度 : " + _vm._s(_vm.strength))]), _c('Progress', {
    staticStyle: {
      "margin": "13px 0"
    },
    attrs: {
      "percent": _vm.strengthValue,
      "status": _vm.progressStatus,
      "hide-info": ""
    }
  }), _c('br'), _vm._v("请至少输入 6 个字符。请不要使 "), _c('br'), _vm._v("用容易被猜到的密码。 ")], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };