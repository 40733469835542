import axios from "axios";
import { getMenuList } from "@/api/index";
export default {
  name: "search",
  props: {
    theme: {
      type: String,
      default: "darkblue"
    },
    type: {
      type: String,
      default: "input"
    }
  },
  data() {
    return {
      key: "",
      list: []
    };
  },
  computed: {},
  methods: {
    init() {
      let menuData = this.getStore("menuData");
      if (!menuData) {
        // 第一次加载 读取数据
        let accessToken = this.getStore("accessToken");
        // 加载菜单
        axios.get(getMenuList, {
          headers: {
            accessToken: accessToken
          }
        }).then(res => {
          menuData = res.result;
          this.list = this.getList(menuData);
        });
      } else {
        menuData = JSON.parse(menuData);
        this.list = this.getList(menuData);
      }
    },
    getList(menuData) {
      let list = [];
      menuData.forEach(function (e) {
        if (e.children && e.children.length > 0) {
          // 1
          e.children.forEach(function (c) {
            if (c.children && c.children.length > 0) {
              // 2
              c.children.forEach(function (b) {
                list.push({
                  title: b.title,
                  name: b.name
                });
              });
            }
          });
        }
      });
      return list;
    },
    changeMenu(name) {
      if (!name) {
        return;
      }
      if (name == this.$route.name) {
        this.$Message.warning("当前已在该页面菜单");
        return;
      }
      this.$router.push({
        name: name
      });
    },
    clear() {
      this.$refs.select.clearSingleSelect();
    }
  },
  mounted() {
    this.init();
  }
};