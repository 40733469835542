var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: `nav-beadcrumb-${_vm.theme}`
  }, [_c('Breadcrumb', _vm._l(_vm.currentPath, function (item) {
    return _c('BreadcrumbItem', {
      key: item.name,
      attrs: {
        "to": item.path
      }
    }, [_vm.currentPath.length > 1 ? _c('span', [_vm._v(_vm._s(_vm.itemTitle(item)))]) : _vm._e()]);
  }), 1), _c('div')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };