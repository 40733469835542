export default {
  name: "sidebarMenu",
  data() {
    return {};
  },
  computed: {
    accordion() {
      return this.$store.state.theme.theme.accordion;
    }
  },
  props: {
    menuList: Array,
    iconSize: Number,
    theme: {
      type: String,
      default: "darkblue"
    },
    openNames: {
      type: Array
    }
  },
  methods: {
    changeMenu(active) {
      this.$emit("on-change", active);
    },
    handleSelect(name) {
      this.$emit("on-select", name);
    },
    itemTitle(item) {
      if (item.localize && item.i18n) {
        return this.$t(item.i18n);
      } else {
        return item.title;
      }
    },
    getOpenedNamesByActiveName(name) {
      return this.$route.matched.map(item => item.name).filter(item => item !== name);
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.sideMenu) {
        this.$refs.sideMenu.updateOpened();
      }
    });
  }
};