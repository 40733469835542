import SetPassword from "@/views/my-components/xboot/set-password";
import { changePass } from "@/api/index";
export default {
  name: "change-pass",
  components: {
    SetPassword
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const valideRePassword = (rule, value, callback) => {
      if (value !== this.form.newPass) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      visible: this.value,
      maxHeight: 510,
      submitLoading: false,
      form: {
        oldPass: "",
        newPass: "",
        rePass: ""
      },
      strength: "",
      formValidate: {
        oldPass: [{
          required: true,
          message: "请输入原密码",
          trigger: "change"
        }],
        newPass: [{
          required: true,
          message: "请输入新密码",
          trigger: "change"
        }, {
          min: 6,
          message: "请至少输入6个字符",
          trigger: "blur"
        }, {
          max: 32,
          message: "最多输入32个字符",
          trigger: "change"
        }],
        rePass: [{
          required: true,
          message: "请再次输入新密码",
          trigger: "change"
        }, {
          validator: valideRePassword,
          trigger: "change"
        }]
      }
    };
  },
  methods: {
    changeInputPass(v, grade, strength) {
      this.strength = strength;
    },
    submit() {
      let params = {
        password: this.form.oldPass,
        newPass: this.form.newPass,
        passStrength: this.strength
      };
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.submitLoading = true;
          changePass(params).then(res => {
            this.submitLoading = false;
            if (res.success) {
              this.visible = false;
              this.$Modal.success({
                title: "修改密码成功",
                content: "修改密码成功，请保管好您的新账号密码"
              });
            }
          });
        }
      });
    },
    setCurrentValue(value) {
      if (value === this.visible) {
        return;
      }
      this.visible = value;
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    },
    visible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
  }
};