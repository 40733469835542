import { ws, getMessageSendData, allMessageSend } from "@/api/index";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import util from "@/libs/util.js";
export default {
  name: "messageTip",
  props: {},
  data() {
    return {
      loading: false,
      userId: "",
      messageList: [],
      client: null
    };
  },
  computed: {
    messageCount() {
      return this.$store.state.app.messageCount;
    }
  },
  stompClient: {
    monitorIntervalTime: 100,
    stompReconnect: true,
    timeout(orgCmd) {}
  },
  methods: {
    init() {
      this.userId = this.getUserInfo().id;
      this.getData();
      // 消息开关 websocket
      let messageOpen = this.getStore("messageOpen");
      if (messageOpen != "0") {
        this.connect();
      }
    },
    getData() {
      // 读取未读消息
      this.loading = true;
      getMessageSendData({
        userId: this.userId,
        status: 0,
        pageSize: 5,
        sort: "createTime",
        order: "desc"
      }).then(res => {
        if (res.success) {
          this.loading = false;
          this.messageList = res.result.content;
          this.$store.commit("setMessageCount", res.result.totalElements);
        }
      });
    },
    showMessage() {
      util.openNewPage(this, "message_index");
      if (this.$route.name == "message_index") {
        return;
      }
      this.$router.push({
        name: "message_index"
      });
    },
    connect() {
      let headers = {};
      this.client = Stomp.over(new SockJS(ws));
      this.client.connect(headers, this.onConnected, this.onFailed);
    },
    onConnected(frame) {
      console.log("连接ws成功: " + frame);
      let topicSubscription = this.client.subscribe("/topic/subscribe", this.responseCallback);
      let queueSubscription = this.client.subscribe("/user/" + this.userId + "/queue/subscribe", this.responseCallback);
    },
    onFailed(frame) {
      console.log("连接ws失败： " + JSON.stringify(frame));
    },
    responseCallback(frame) {
      console.log("收到消息：" + frame.body);
      this.getData();
    },
    messageDetail(v) {
      if (v.status == 1) {
        return;
      }
      util.openNewPage(this, "message_index");
      this.$router.push({
        name: "message_index",
        query: {
          count: this.messageCount,
          content: v
        }
      });
      v.status = 1;
    },
    markAll() {
      allMessageSend(0).then(res => {
        if (res.success) {
          this.getData();
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};