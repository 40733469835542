export default {
  xboot: 'XBoot 是很不错的一站式前后端分离快速开发平台',
  usernameLogin: '账户密码登录',
  mobileLogin: '手机短信登录',
  autoLogin: '自动登录',
  forgetPass: '忘记密码',
  otherLogin: '其他方式登录',
  registerAccount: '注册账户',
  register: "注册",
  registering: "注册中...",
  registerNow: "还没有账号？立即注册",
  loginNow: "使用已有账号登录",
  login: '登录',
  logining: '登录中...',
  home: '首页',
  xbootAdmin: 'XBoot管理系统',
  appAdmin: 'App管理 (toC)',
  xbootMaterial: 'XBoot物料中心',
  devDocs: '开发文档',
  permTestPage: '权限按钮测试页',
  ssoTestPage: '单点登录测试页',
  more: '更多',
  searchMenu: '搜索菜单...',
  fullscreen: '全屏',
  exitFullscreen: '退出全屏',
  Notifications: '消息通知',
  markAll: '标记所有已读',
  refresh: '刷新',
  noNewMes: '暂无新的未读消息',
  showMore: '查看更多',
  userCenter: '个人中心',
  changePass: '修改密码',
  logout: '退出登录',
  closeAll: '关闭全部',
  closeOthers: '关闭其他',
  docs: '开发文档',
  fullVersion: 'XBoot完整版',
  rights: '版权所有',
  sso: "XBoot统一认证平台",
  authorize: '授权',
  authorizeAndSignin: "授权并登录",
  authorizing: "授权中...",
  wants: "想要获取您的账户",
  wants2: "的权限",
  authAquire: "授权后，将获得以下用户数据",
  aquireInfo: "获得您的昵称、头像、性别",
  afterAuth: "授权后将会跳转至",
  relateTitle: "绑定XBoot账号",
  relate: "立即绑定",
  relating: "绑定中...",
  themeTitle: "主题样式配置",
  wholeSetting: "整套风格预设",
  lightblue: "亮蓝色风格",
  topNav: "顶部菜单风格",
  darkblue: "暗蓝色风格",
  darkMode: "暗黑模式",
  dark: "黑色风格",
  middle: "中性风格",
  white: "亮白色风格",
  sideMenu: "侧边菜单风格",
  navMenu: "导航栏风格",
  navMode: "导航栏模式",
  rightTop: "右上侧",
  topFix: "顶部置顶",
  navType: "导航菜单展示",
  horizontal: "横向多菜单",
  sliceNum: "默认显示个数",
  singleDrop: "单个下拉菜单",
  iconBreadcrumb: "图标+面包屑",
  showIcon: "是否显示图标",
  dropMenu: "下拉菜单展示",
  appStyle: "App图标应用",
  listStyle: "列表模式",
  contentArea: "内容区域",
  enableTags: "启用Tags多标签",
  showLogo: "显示Logo",
  showFold: "显示折叠菜单图标",
  showSearchMenu: "显示搜索菜单",
  showFooter: "显示页脚",
  sideMenuAccordion: "侧边菜单手风琴模式",
  sideMenuWidth: "侧边菜单宽度",
  waterMark: "水印设置",
  openWaterMark: "开启水印",
  opacity: "不透明度",
  fontSize: "字体大小",
  otherSetting: "其他设置",
  weakMode: "色弱模式",
  themeInfo: "主题配置主要用于预览布局效果，建议在生产环境仅为用户开放部分功能或关闭该功能"
};