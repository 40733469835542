import apps from "./apps";
export default {
  name: "nav-app",
  components: {
    apps
  },
  props: {
    navList: Array,
    currNav: String,
    currNavTitle: String,
    placement: {
      type: String,
      default: "bottom"
    },
    currType: {
      type: String,
      default: "icon"
    },
    type: {
      type: String,
      default: "app"
    },
    theme: {
      type: String,
      default: "darkblue"
    }
  },
  data() {
    return {};
  },
  methods: {
    itemTitle(item) {
      if (item.localize && item.i18n) {
        return this.$t(item.i18n);
      } else {
        return item.title;
      }
    },
    selectNav(v) {
      this.$emit("on-click", v);
    }
  },
  watch: {}
};